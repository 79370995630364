import React, { Component } from "react";
import { Form, message } from "antd";
import PhoneVerificationModal from "../components/PhoneVerificationModal";
import { PostRequest } from "../APIRequest/APIRequest";
import { getEmailAndJWT } from "../constants";
import { UserRole } from "../constants";

class Apply extends Component {
  state = {
    visible: false,
    email: "",
  };

  componentDidMount() {
    localStorage.setItem("role", UserRole.STUDENT);
    const { email, JWT } = getEmailAndJWT();
    this.setState({ email });
    this.getUserPhoneByEmail(email, JWT);
  }

  getUserPhoneByEmail = async (email, JWT) => {
    try {
      const response = await PostRequest("sms/getUserPhoneByEmail", {
        params: { email },
        headers: { Authorization: `JWT ${JWT}` },
      });

      if (!response) {
        message.error("Network Error");
        throw new Error("Network Error");
      }

      if (response.status === 200) {
        this.setState({ visible: false });
        return response.data.message;
      } else if (response.status === 500) {
        this.setState({ visible: true });
        throw new Error(response.data.message);
      } else {
        throw new Error("Unknown Error");
      }
    } catch (error) {
      console.log("User Phone not found in db: ", error);
    }
  };

  render() {
    const { children, form } = this.props;
    const { visible, email } = this.state;
    const JWT = localStorage.getItem("JWT");

    return (
      <React.Fragment>
        <div
          className="w-100 h-100 justify-content-center"
          style={{ backgroundColor: "#486a79" }}
        >
          <PhoneVerificationModal
            visible={visible}
            email={email}
            JWT={JWT}
            onClose={() => this.setState({ visible: false })}
            form={form}
          />
          <div className="w-100 align-middle">{children}</div>
        </div>
      </React.Fragment>
    );
  }
}

export default Form.create()(Apply);
