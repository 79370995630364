const initialState = {
  degreeType: null,
  program: null,
  concentration: null,
};

const priceFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FILTER":
      return { ...state, filter: action.payload };
    default:
      return state;
  }
};

export default priceFilterReducer;
