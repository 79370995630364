import axios from "axios";
import { getEmailAndJWT } from "../constants";

const deploy_mode = process.env.NODE_ENV || "production"; // use NODE_ENV or REACT_APP_
/* Note: see https://create-react-app.dev/docs/adding-custom-environment-variables/
Note: You must create custom environment variables beginning with REACT_APP_. 
Any other variables except NODE_ENV will be ignored to avoid accidentally 
exposing a private key on the machine that could have the same name. Changing 
any environment variables will require you to restart the development server if 
it is running. */

let backendRequestUrl = "";
// backendRequestUrl = "https://api.oxygen.patten.edu:5010/api/";
if (deploy_mode === undefined || deploy_mode === "production") {
  backendRequestUrl = "https://api.oxygen.linq.ai:3020/api/";
} else {
  backendRequestUrl = "http://localhost:3020/api/";
}

export const PostRequest = (router, payload, headers = null) => {
  // console.log(payload);
  let requestUrl = backendRequestUrl;
  return axios
    .post(requestUrl + router, payload, headers)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const GetRequest = async (router, payload, headers = null) => {
  // console.log(payload);
  let requestUrl = backendRequestUrl;
  try {
    const res = await axios.get(requestUrl + router, payload, headers); // TODO
    return res;
  } catch (err) {
    return err.response;
  }
};

export const FetchData = async (url) => {
  const { email, JWT } = getEmailAndJWT();
  if (!JWT || !email) {
    throw new Error("User Authorization Failed");
  }

  const response = await GetRequest(url, {
    params: {
      email,
    },
    headers: { Authorization: `JWT ${JWT}` },
  });

  if (!response) {
    throw new Error("Network Error");
  } else if (response.status !== 200) {
    console.log("Fetch data error");
    throw new Error("Fetch data error");
  }

  return response.data;
};

export const PostNoAuthData = async (url, formData) => {
  const response = await PostRequest(url, formData);
  if (!response) {
    throw new Error("Network Error");
  } else if (response.status !== 200) {
    console.log("Post data error");
    throw new Error("Post data error");
  }
  return response.data;
};

export const PostAuthData = async ([url, formData]) => {
  const { email, JWT } = getEmailAndJWT();
  if (!JWT || !email) {
    throw new Error("User Authorization Failed");
  }

  const response = await PostRequest(url, formData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${JWT}`,
    },
  });

  if (!response) {
    throw new Error("Network Error");
  } else if (response.status !== 200) {
    console.log("Fetch data error");
    throw new Error("Fetch data error");
  }

  return response.data;
};

export default { PostRequest, GetRequest };
