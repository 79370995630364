import React, { Component } from "react";
import { Form, message } from "antd";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Settings from "../components/Settings";
import { isAuthenticated } from "../components/Authenticate";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { loginEmailLayout } from "../redux/actions/layoutActions";
import { UserRole, getEmailAndJWT } from "../constants";
import { PostRequest } from "../APIRequest/APIRequest";
import PhoneVerificationModal from "../components/PhoneVerificationModal";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gotoLogin: false,
      email: "",
      visible: false,
      prefix: "86",
      errorMessage: "",
      validated: false,
      verificationLoading: false,
      yztime: 59,
    };
  }

  getUserPhoneByEmail = async (email, JWT) => {
    try {
      const response = await PostRequest("sms/getUserPhoneByEmail", {
        params: { email },
        headers: { Authorization: `JWT ${JWT}` },
      });

      if (!response) {
        message.error("Network Error");
        throw new Error("Network Error");
      }

      if (response.status === 200) {
        this.setState({
          visible: false,
        });
        return response.data.message;
      } else if (response.status === 500) {
        this.setState({
          visible: true,
        });
        throw new Error(response.data.message);
      } else {
        throw new Error("Unknown Error");
      }
    } catch (error) {
      console.log("User Phone not found in db: ", error);
    }
  };

  componentDidMount = () => {
    const { dispatch } = this.props;
    localStorage.setItem("role", UserRole.STUDENT);
    const { email, JWT } = getEmailAndJWT();
    isAuthenticated((err, usr) => {
      if (err) {
        this.setState({
          gotoLogin: true,
        });
      } else {
        if (usr.email === "") {
          this.setState({
            gotoLogin: true,
          });
        } else {
          usr.token = localStorage.getItem("JWT");
          this.setState({
            gotoLogin: false,
            email: usr.email,
          });
          dispatch(loginEmailLayout(usr));
        }
      }
    });
    this.getUserPhoneByEmail(email, JWT);
  };

  componentDidUpdate(prevProps) {
    if (this.props.layout !== prevProps.layout) {
      isAuthenticated((err, usr) => {
        if (err) {
          this.setState({
            gotoLogin: true,
          });
        } else {
          if (usr.email === "") {
            this.setState({
              gotoLogin: true,
            });
          } else {
            usr.token = localStorage.getItem("JWT");
            this.setState({
              gotoLogin: false,
              email: usr.email,
            });
          }
        }
      });
    }
  }

  render() {
    if (this.state.gotoLogin) {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: this.props.location },
          }}
        />
      );
    }

    return (
      <React.Fragment>
        <Wrapper>
          <Sidebar />
          <Main>
            <Navbar />
            <Content>{this.props.children}</Content>
            {/*<Footer />*/}
          </Main>
        </Wrapper>
        <Settings />
        <PhoneVerificationModal
          visible={this.state.visible}
          email={this.state.email}
          JWT={localStorage.getItem("JWT")}
          onClose={() =>
            this.setState({
              visible: false,
            })
          }
          form={this.props.form}
        />
      </React.Fragment>
    );
  }
}

export default connect((store) => ({
  layout: store.layout,
}))(Form.create()(Dashboard));
