import {GetRequest} from '../APIRequest/APIRequest'

export const isAuthenticated = (callback) => {
  const JWT = localStorage.getItem('JWT');
  const email = localStorage.getItem('email');
  // const lastManualLogout = localStorage.getItem('manualLogout')
  if (JWT === null || email === null) {
    // if (lastManualLogout === null) message.error('User Authorization Failed')
    callback('User Authorization Failed', null);
  }

  GetRequest("user/info?", {
    params: {
      email: email
    },
    headers: {Authorization: `JWT ${JWT}`},
  })
    .then(res => {
      if (!res) {
        // message.error('Network Error');
        callback('Network Error', null);
      } else if (res && res.status === 200) {
        callback(null, res.data.user)
      } else if (res.data) {
        // message.error(res.data.err)
        callback(res.data.err, null)
      }
    })
    .catch(error => {
      console.log("get user info error: ", error)
      // message.error(error)
      callback(error, null)
    })
}

export default {isAuthenticated}