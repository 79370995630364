import React, { useState, useEffect } from "react";
import { Form, message, Modal, Input, Select, Icon, Button } from "antd";
import { Col, Row } from "reactstrap";
import { PostRequest } from "../APIRequest/APIRequest";
import { countryData } from "../constants";

const { Option } = Select;

const PhoneVerificationModal = ({ visible, email, JWT, onClose, form }) => {
  const { getFieldDecorator } = form;
  const [prefix, setPrefix] = useState("86");
  const [validated, setValidated] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [yztime, setYztime] = useState(59);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    let siv;
    if (verificationLoading) {
      siv = setInterval(() => {
        setYztime((currentYzTime) => {
          if (currentYzTime <= 1) {
            setVerificationLoading(false);
            clearInterval(siv);
            return 59;
          }
          return currentYzTime - 1;
        });
      }, 1000);
    }

    return () => clearInterval(siv);
  }, [verificationLoading]);

  const handlePrefixChange = (value) => {
    setPrefix(value);
  };

  const getOptionLabel = (option) => {
    return `${option.flag} +${option.prefix} (${option.code})`;
  };

  const prefixSelector = (
    <Select
      showSearch
      style={{ width: 150 }}
      value={prefix}
      onChange={handlePrefixChange}
      optionFilterProp="children"
      filterOption={(input, option) => {
        const optionLabel = getOptionLabel(option.props.country);
        return optionLabel.toLowerCase().includes(input.toLowerCase());
      }}
    >
      {countryData.map((country) => (
        <Option key={country.prefix} value={country.prefix} country={country}>
          {getOptionLabel(country)}
        </Option>
      ))}
    </Select>
  );

  const countDown = () => {
    const siv = setInterval(() => {
      setYztime((currentYzTime) => {
        if (currentYzTime <= 0) {
          clearInterval(siv);
          setVerificationLoading(false);
          return 59;
        }
        return currentYzTime - 1;
      });
    }, 1000);
  };

  const sendVerification = async (e) => {
    setVerificationLoading(true);
    e.preventDefault();
    countDown();
    var phoneNumber = form.getFieldValue("phone");
    // Use a regular expression to remove non-numeric characters
    phoneNumber = phoneNumber.replace(/\D/g, '');

    const prefixedPhoneNumber = '+' + prefix + '-' + phoneNumber;
    try {
      const data = await PostRequest("sms/send-sms", {
        phoneNumber: prefixedPhoneNumber,
        email,
      });
      if (data.data.success) {
        message.success("SMS sent successfully");
        console.log("SMS sent successfully");
      } else {
        message.error("Failed to send SMS");
        console.error("Failed to send SMS:", data.message);
      }
    } catch (error) {
      setVerificationLoading(false);
      message.error("Failed to send SMS");
      console.error("Error sending SMS:", error);
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    const verificationCode = form.getFieldValue("verificationCode");
    var phoneNumber = form.getFieldValue("phone");
    // Use a regular expression to remove non-numeric characters
    phoneNumber = phoneNumber.replace(/\D/g, '');
    const prefixedPhoneNumber = '+' + prefix + '-' + phoneNumber;
    try {
      const response = await PostRequest(
        "sms/verifyCode",
        {
          phoneNumber: prefixedPhoneNumber,
          code: verificationCode,
          email,
        },
        {
          headers: { Authorization: `JWT ${JWT}` },
        }
      );

      if (response.data.success) {
        onClose();
        message.success("Verify successfully");
        setErrorMessage("");
        setValidated(true);
        console.log("Verify successfully");
      } else if (response.data.message === "Verification code expired") {
        message.error("Verification code expired");
        setErrorMessage("Verification code expired");
        setValidated(false);
        console.error("Verification code expired");
      } else if (response.data.message === "Incorrect Verification code") {
        message.error("Incorrect Verification code");
        setErrorMessage("Incorrect Verification code");
        setValidated(false);
        console.error("Incorrect Verification code");
      }
    } catch (error) {
      message.error("Failed to verify SMS");
      setErrorMessage("Failed to verify SMS");
      setValidated(false);
      console.error("Error verify SMS:", error);
    }
  };

  return (
    <Modal
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          Link your phone number
        </div>
      }
      visible={visible}
      bodyStyle={{ backgroundColor: "#f0f2f5" }}
      footer={null}
      closable={false}
      centered={true}
    >
      <Form className="verification-form">
        <Form.Item>
          {getFieldDecorator("phone", {
            rules: [
              {
                required: true,
                message: "Please input your phone number!",
              },
              {
                pattern: /^[0-9]+$/,
                message: "Please enter a valid phone number.",
              },
            ],
          })(
            <Input
              addonBefore={prefixSelector}
              prefix={
                <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
              }
              className="big-input"
              style={{ width: "100%", borderBlockColor: "black" }}
            />
          )}
        </Form.Item>
        <Form.Item
          validateStatus={errorMessage ? "error" : validated ? "success" : ""}
          help={errorMessage}
        >
          {getFieldDecorator("verificationCode", {
            rules: [
              {
                required: true,
                message: "Please input your verification Code!",
              },
            ],
          })(
            <Row>
              <Col lg="6" xs="6">
                <Input
                  className="big-input"
                  style={{ width: "100%" }}
                  prefix={
                    <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="verificationCode"
                  placeholder={"Verification Code"}
                  suffix={
                    validated ? (
                      <Icon type="check" style={{ paddingRight: 10 }} />
                    ) : null
                  }
                />
              </Col>
              <Col lg="6" xs="6" className="d-flex justify-content-end">
                <Button
                  type="primary"
                  style={{ height: "61px", width: "100%" }}
                  loading={verificationLoading}
                  htmlType="submit"
                  onClick={sendVerification}
                  disabled={!form.getFieldValue("phone")}
                >
                  {verificationLoading ? yztime + "s" : "Send verification"}
                </Button>
              </Col>
            </Row>
          )}
        </Form.Item>
        <Form.Item>
          <Row className="d-flex justify-content-center align-items-center">
            <Button
              type="primary"
              style={{ height: "61px", width: "50%" }}
              htmlType="submit"
              onClick={submit}
              disabled={
                !form.getFieldValue("verificationCode") ||
                !form.getFieldValue("phone")
              }
            >
              Submit
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create()(PhoneVerificationModal);
