import React, {Component} from 'react';
import Avatar from '@material-ui/core/Avatar';

class OxygenAvatar extends Component{
	constructor(props){
	    super(props);
	    this.state={
	    	firstLetter: '',
	    	bgColor: 'grey'
	    };
	 }

	componentDidMount = () => {
		this.updateAvatar();
	}

	componentDidUpdate(prevProps) {
	  if(this.props.username !== prevProps.username)
	  {
	    this.updateAvatar();
	  }
	} 

	updateAvatar = () => {
		if (this.props.username) {
			const name = this.props.username
			const clr = this.stringToColor(name)
			this.setState({
				firstLetter: name.charAt(0),
				bgColor: clr
			})
		} else {
			this.setState({
				firstLetter: '',
				bgColor: 'gray'
			})	
		}
	}

	stringToColor = (string) => {
		let hash = 0;
		let i;

		/* eslint-disable no-bitwise */
		for (i = 0; i < string.length; i += 1) {
			hash = string.charCodeAt(i) + ((hash << 5) - hash);
		}

		let color = '#';

		for (i = 0; i < 3; i += 1) {
			const value = (hash >> (i * 8)) & 0xff;
			color += `00${value.toString(16)}`.substr(-2);
		}
		/* eslint-enable no-bitwise */

		return color;
	}


	render(){
		return (
			<Avatar style={{
				marginLeft: this.props.marginLeft,
				marginRight: this.props.marginRight,
				backgroundColor: this.state.bgColor,
				fontSize: this.props.fontSize,
				width: this.props.width,
				height: this.props.height,
				display: 'inline-flex',
			}}>{this.state.firstLetter}</Avatar>
		)
	}
}

export default OxygenAvatar;