import React from "react";
import Main from "../components/Main";

const NewWindowLayout = ({ children }) => (
    <React.Fragment>
        <Main className="w-100 justify-content-center" style={{backgroundColor: "white"}}>
            <div className="w-100 align-middle" style={{padding: "20px"}}>{children}</div>
        </Main>
    </React.Fragment>
);

export default NewWindowLayout;