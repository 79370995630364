import React, { useEffect } from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";

import store from "./redux/store/index";
import Routes from "./routes/Routes";
import baiduCode from "../src/baiduCode";

const App = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = baiduCode;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Provider store={store}>
      <Routes />
      <ReduxToastr
        timeOut={5000}
        newestOnTop={true}
        position='top-right'
        transitionIn='fadeIn'
        transitionOut='fadeOut'
        progressBar
        closeOnToastrClick
      />
    </Provider>
  );
};

export default App;
