import React from "react";
import MainForAdminLogin from "../components/MainForAdminLogin";

const Admin = ({ children }) => (
  <React.Fragment>
    <MainForAdminLogin className="w-100 justify-content-center">
      <div className="w-100 align-middle">{children}</div>
    </MainForAdminLogin>
  </React.Fragment>
);

export default Admin;
