export function checkReadViewMode(propsLocationSearch) {
  const re = /readView(?!=(0|false))/i
  return propsLocationSearch.search(re) !== -1;
}

export function getExpandModuleIdx(domLocationSearch) {
  const re = /expand=([0-9]+)/i
  let match = domLocationSearch.match(re)
  if (match) {
    try {
      return parseInt(match[1])
    } catch (e) {
      return 0;
    }
  } else {
    return 0;
  }
}
