import React from "react";
import classNames from "classnames";

const MainForAdminLogin = ({ className, children }) => {
  return (
    <div
      style={{ backgroundColor: "#283646" }}
      className={classNames("main", className)}
    >
      {children}
    </div>
  );
};

export default MainForAdminLogin;
