import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  Navbar,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  NavbarBrand,
} from "reactstrap";
import { isAuthenticated } from "../../components/Authenticate";
import { UserRole } from "../../constants";
import logo from "../../assets/img/brands/oxygen.svg";
import girl from "../../assets/img/landing/girl.webp";
import google from "../../assets/img/landing/google.svg";
import linkedin from "../../assets/img/landing/linkedin.webp";
import meta from "../../assets/img/landing/meta.webp";
import patten from "../../assets/img/landing/patten.webp";
import mit from "../../assets/img/landing/mit.svg";
import stanford from "../../assets/img/landing/stanford.svg";

export default function LandingPage() {
  let ref_id = "UY4YH7dWWQ";
  if (localStorage.getItem("ref_id") !== "") {
    ref_id = localStorage.getItem("ref_id");
  }
  localStorage.setItem("ref_id", ref_id);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const history = useHistory();
  const goToApply = () => {
    isAuthenticated((err, usr) => {
      if (err) {
        localStorage.setItem("role", UserRole.STUDENT);
        history.push("/login", { from: "apply" });
      } else {
        if (usr.email === "") {
          localStorage.setItem("role", UserRole.STUDENT);
          history.push("/login", { from: "apply" });
        } else {
          usr.token = localStorage.getItem("JWT");
          history.push("/apply", { role: UserRole.STUDENT });
        }
      }
    });
  };

  const goToStudent = () => {
    localStorage.setItem("role", UserRole.STUDENT);
    history.push(`/login?ref_id=${ref_id}`, { role: UserRole.STUDENT });
  };

  const goToTeacher = () => {
    localStorage.setItem("role", UserRole.ADMIN);
    history.push(`/admin-login`);
  };

  return (
    <div className="landing">
      <Navbar
        color="light"
        light
        expand={false}
        className="fixed-top d-md-none"
      >
        <NavbarBrand href="/" className="me-auto">
          <img
            alt="logo"
            style={{ width: "4.2rem", height: "4.2rem" }}
            src={logo} 
          />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} className="me-2 ml-auto" />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem className="me-2 ml-auto">
              <NavLink onClick={goToApply}>Apply Now</NavLink>
            </NavItem>
            <NavItem className="me-2 ml-auto">
              <NavLink onClick={goToStudent}>Student Portal</NavLink>
            </NavItem>
            <NavItem className="me-2 ml-auto">
              <NavLink onClick={goToTeacher}>Teacher Portal</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      <Container fluid>
        <Row className="d-flex justify-content-center align-items-center landing-content mt-6">
          <Col xl="6" xs="12" className="landing-content-left">
            <img alt="logo" src={logo} className="landingLogo"/>             
            <div className="paragraph1">
              <h1>Launch a new career</h1>
              <h1>with hybrid learning</h1>
              <h1>experience</h1>
            </div>
            <div className="paragraph2">
              <p className="mb-0">
                Start, switch, or advance your career with more than 600 High-
              </p>
              <p className="mb-0">
                quality courses, Professional Certificates, and best Degrees
                from
              </p>
              <p className="mb-0">world-class universities and companies.</p>
            </div>
            <ButtonGroup className="buttonGroup">
              <Button
                onClick={goToApply}
                color="secondary"
                className="buttonApply"
              >
                APPLY NOW
              </Button>
              <Button
                onClick={goToStudent}
                color="secondary"
                className="buttonStudent"
              >
                STUDENT PORTAL
              </Button>
              <Button
                onClick={goToTeacher}
                color="light"
                className="buttonTeacher"
              >
                TEACHER OR STAFF PORTAL
              </Button>
            </ButtonGroup>
          </Col>
          <Col xl="6" xs="12" className="landing-content-right">
             {" "}
            <div className="d-flex justify-content-start  align-items-center">
                  <img src={logo} alt="oxygen" className="img-fluid oxygen" />
                      <img src={girl} alt="girl" className="img-fluid girl" /> 
                 {" "}
            </div>
          </Col>
        </Row>
        <Row className="landing-footer">
          <Col
            sm="12"
            className="d-flex justify-content-center align-items-center flex-column"
          >
               {" "}
            <div className="align-items-center">
                   {" "}
              <a
                style={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  color: "#000000",
                  pointerEvents: "none",
                  cursor: "default",
                }}
              >
                We collaborate with{" "}
                <span style={{ color: "blue" }}>
                  45+ leading universities and companies
                </span>
              </a>
                 {" "}
            </div>
               {" "}
            <div className="align-items-center">
              <div
                className="d-flex justify-content-start align-items-center image-container"
                style={{ marginTop: "1.25rem" }}
              >
                <img src={patten} alt="patten" />
                <img src={mit} alt="mit" />
                <img src={stanford} alt="stanford" />
                <img src={linkedin} alt="linkedin" />
                <img src={meta} alt="meta" />
                <img src={google} alt="google" />
              </div>{" "}
                 {" "}
            </div>
               {" "}
            <div className="align-items-center">
              <p style={{ fontSize: "0.75rem", color: "#B3B7B8" }}>
                © {new Date().getFullYear()} LINQ.AI All rights reserved.
              </p>
                 {" "}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
