import * as types from "../constants";

export function toggleBoxedLayout() {
  return {
    type: types.LAYOUT_BOXED_TOGGLE
  };
}

export function enableBoxedLayout() {
  return {
    type: types.LAYOUT_BOXED_ENABLE
  };
}

export function disableBoxedLayout() {
  return {
    type: types.LAYOUT_BOXED_DISABLE
  };
}

export function loginEmailLayout(value) {
  return {
    type: types.LAYOUT_EMAIL_UPDATE,
    payload: value
  };
}

export function updateCoursesLayout(value) {
  return {
    type: types.LAYOUT_COURSES_UPDATE,
    payload: value
  };
}

export function updateModuleLayout(value) {
  return {
    type: types.LAYOUT_MODULE_UPDATE,
    payload: value
  };
}

export function signOut() {
  return {
    type: types.LAYOUT_SIGNOUT
  };
}