import * as types from "../constants";

const initialState = {
  isBoxed: false,
  user: {},
  courses: [],
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.LAYOUT_BOXED_TOGGLE:
      return {
        ...state,
        isBoxed: !state.isBoxed
      };
    case types.LAYOUT_BOXED_ENABLE:
      return {
        ...state,
        isBoxed: true
      };
    case types.SIDEBAR_STICKY_ENABLE:
    case types.SIDEBAR_STICKY_TOGGLE:
    case types.LAYOUT_BOXED_DISABLE:
      return {
        ...state,
        isBoxed: false
      };
    case types.LAYOUT_EMAIL_UPDATE:
      const usr = actions.payload;
      // localStorage.setItem('role', usr.role);
      localStorage.setItem('JWT', usr.token);
      localStorage.setItem('email', usr.email);
      return {
        ...state,
        user: usr
      };
    case types.LAYOUT_COURSES_UPDATE:
      return {
        ...state,
        courses: actions.payload
      };
    case types.LAYOUT_MODULE_UPDATE:
      return {
        ...state,
        modules: actions.payload
      };
    case types.LAYOUT_SIGNOUT:
      localStorage.removeItem('JWT');
      localStorage.removeItem('email');
      return {
        ...state,
        user: {}
      };
    default:
      return state;
  }
}
