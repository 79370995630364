import React from "react";
import classNames from "classnames";
// import { UserRole, getRoleFromLocalStorage } from "../constants";

const MainForLogin = ({ className, children }) => {
  // const role = getRoleFromLocalStorage();
  // const backgroundColor = role === UserRole.ADMIN ? "#283646" : null;

  return (
    <div
      style={{ backgroundColor: "white" }}
      className={classNames("main", className)}>
      {children}
    </div>
  );
};

export default MainForLogin;
