const initialState = {
  price: null,
};

const priceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PRICE":
      return { ...state, price: action.payload };
    case "SET_PROGRAM_NAME":
      return { ...state, programName: action.payload };
    case "SET_FIRST_NAME":
      return { ...state, firstName: action.payload };
    case "SET_LAST_NAME":
      return { ...state, lastName: action.payload };
    default:
      return state;
  }
};

export default priceReducer;
