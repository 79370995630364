import React from "react";
import MainForLogin from "../components/MainForLogin";

const RoleBackground = ({ children }) => (
  <React.Fragment>
    <MainForLogin className='w-100 justify-content-center'>
      <div className='w-100 align-middle'>{children}</div>
    </MainForLogin>
  </React.Fragment>
);

export default RoleBackground;
